import { FirebaseError } from 'firebase/app';

interface ErrorMessages {
  [key: string]: string;
}

export function fireBaseErrorHandler(error: FirebaseError) {
  const errorMessages: ErrorMessages = {
    'auth/email-already-exists': 'O e-mail informado já está em uso!',
    'auth/internal-error': 'Ocorreu um erro no Servidor de Autenticação!',
    'auth/user-not-found': 'Usuário não encontrado!',
    'auth/wrong-password': 'E-mail e/ou senha incorretos!',
  };

  const message = errorMessages[error.code];

  if (!message) {
    return 'Ocorreu um erro ao processar a solicitação!';
  }

  return message;
}
