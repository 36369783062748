import { LoadingButton } from '@mui/lab';
import {
  Box,
  Link,
  styled,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormikProps } from 'formik';
import { motion } from 'framer-motion';
import React from 'react';
import { defaultColors } from '../../../../../../helpers/utils';
import { PublicUserRequestForm } from '../../../../../../shared/models';
import PasswordInput from '../../../../../../shared/components/PasswordInput';

const useStyles = makeStyles<Theme>((theme) => ({
  buttonContainer: {
    marginTop: 56,

    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: 0,
      width: 385,
      margin: 0,
      padding: 16,
      backgroundColor: '#fff',
    },

    [theme.breakpoints.down('sm')]: {
      left: 0,
      width: '100%',
    },
  },
}));

interface StyleProps {
  isLoading: boolean;
}

const StyledLoadingButton = styled(LoadingButton)<StyleProps>(
  ({ isLoading }) => ({
    backgroundColor: !isLoading ? defaultColors.primary : undefined,

    transition: 'filter .2s',

    '&:hover': {
      backgroundColor: !isLoading ? defaultColors.primary : undefined,
      filter: 'brightness(.9)',
    },
  })
);

interface LoginFormFourthStepProps {
  isLoading: boolean;
  accountAlreadyExists: boolean;
  goToResetPasswordPage: (email: string) => void;
  formik: FormikProps<PublicUserRequestForm>;
}

const LoginFormFourthStep: React.FC<LoginFormFourthStepProps> = ({
  isLoading,
  accountAlreadyExists,
  goToResetPasswordPage,
  formik,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formik;

  return (
    <Box marginY={isSm ? 3 : 4}>
      {accountAlreadyExists ? (
        <Box mb={isSm ? 3 : 4}>
          <Typography variant={isSm ? 'h6' : 'h5'} style={{ fontWeight: 700 }}>
            Digite sua senha
          </Typography>

          <Typography style={{ color: '#636965', marginTop: isSm ? 16 : 8 }}>
            Insira a senha para poder entrar na sua conta.
          </Typography>
        </Box>
      ) : (
        <Box mb={isSm ? 3 : 4}>
          <Typography variant={isSm ? 'h6' : 'h5'} style={{ fontWeight: 700 }}>
            Crie uma senha
          </Typography>

          <Typography style={{ color: '#636965', marginTop: isSm ? 16 : 8 }}>
            Digite uma senha para poder fazer login
          </Typography>
        </Box>
      )}

      <PasswordInput
        fullWidth
        disabled={isLoading}
        data-testid="password-input"
        variant="outlined"
        label="Senha"
        name="password"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.password !== undefined && touched.password}
        helperText={errors.password && touched.password && errors.password}
      />

      {accountAlreadyExists ? (
        <Box mt={2}>
          <Link
            data-testid="go-to-reset-password-button"
            style={{ fontSize: '.875rem', cursor: 'pointer' }}
            onClick={() => goToResetPasswordPage(values.email)}
          >
            Esqueceu sua senha?
          </Link>
        </Box>
      ) : (
        <Typography
          style={{ fontSize: '.875rem', fontWeight: 600, marginTop: 16 }}
        >
          Ao criar uma conta, você concorda com nossos{' '}
          <Link>Termos de Uso.</Link>
        </Typography>
      )}

      <motion.div
        layoutId="continue-btn-container"
        className={classes.buttonContainer}
      >
        <StyledLoadingButton
          fullWidth
          disableElevation
          loading={isLoading}
          isLoading={isLoading}
          loadingPosition="start"
          disabled={!values.password || !!errors.password}
          variant="contained"
          className={classes.button}
          onClick={() => handleSubmit()}
        >
          {accountAlreadyExists ? 'entrar' : 'criar conta'}
        </StyledLoadingButton>
      </motion.div>
    </Box>
  );
};

export default LoginFormFourthStep;
