import { ArrowBack } from '@mui/icons-material';
import {
  Box,
  Collapse,
  Divider,
  Hidden,
  IconButton,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { FirebaseError } from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  UserCredential,
} from 'firebase/auth';
import { useFormik } from 'formik';
import { AnimatePresence } from 'framer-motion';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import ColoredGoogleIcon from '../../../../../public/svg/ColoredGoogleIcon';
import { auth } from '../../../../firebase/auth';
import { fireBaseErrorHandler } from '../../../../firebase/errorHandler';
import { googleAuthProvider } from '../../../../firebase/providers';
import { defaultColors, SocialSignIn } from '../../../../helpers/utils';
import { validateEmail } from '../../../../helpers/validators/email';
import { setPublicUserInfo } from '../../../../redux/reducers/publicUser/reducer';
import { SocialLoginButtons } from '../../../../shared/components/SocialLoginButtons';
import { PublicUserRequestForm } from '../../../../shared/models';
import { authService } from '../../../../shared/services/authService';
import { publicUserService } from '../../../../shared/services/publicUser';
import LoginFormFirstStep from './components/LoginFormFirstStep';
import LoginFormFourthStep from './components/LoginFormFourthStep';
import LoginFormSecondStep from './components/LoginFormSecondStep';
import LoginFormThirdStep from './components/LoginFormThirdStep';
import ResetPasswordStep from './components/ResetPasswordStep';

const FormHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  [theme.breakpoints.down('md')]: {
    justifyContent: 'space-between',
  },
}));

interface LoginFormProps {
  handleShowNewUserGreeting: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ handleShowNewUserGreeting }) => {
  const dispatch = useDispatch();
  const { push, query } = useRouter();

  const [currentFormStep, setCurrentFormStep] = useState(0);
  const [accountAlreadyExists, setAccountAlreadyExists] = useState(false);
  const [isSocialSignOn, setIsSocialSignOn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeAccountAlreadyExists = (value: boolean) => {
    setAccountAlreadyExists(value);
  };

  const goToNextStep = () => {
    setCurrentFormStep(currentFormStep + 1);
  };

  const goToPreviousStep = () => {
    setCurrentFormStep(currentFormStep - 1);
  };

  const goToLastStep = () => {
    setCurrentFormStep(3);
  };

  const goToResetPasswordPage = async (email: string) => {
    setIsLoading(true);
    await sendPasswordResetEmail(auth, email);
    setCurrentFormStep(4);
    setIsLoading(true);
  };

  const handleSignOnWithGoogle = (user: {
    email: String | null;
    name: string | null;
  }) => {
    setFieldValue('email', user.email);
    setFieldValue('name', user.name);

    setIsLoading(false);
    setIsSocialSignOn(true);

    goToNextStep();
  };

  const signInWithPopUpCallback = (
    token: string,
    userCredentials: UserCredential
  ) => {
    setIsLoading(true);
    SocialSignIn(token, userCredentials)
      .then((userExists) => {
        if (userExists) {
          push('/');
        } else {
          const { email, displayName: name } = userCredentials.user;
          handleSignOnWithGoogle({ email, name });
        }
      })
      .catch(({ message }) => {
        toast.error(message, {
          theme: 'colored',
        });
      })
      .finally(() => setIsLoading(false));
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Informe um e-mail para continuar')
      .test('validateEmail', 'Endereço de e-mail inválido', (value) => {
        return validateEmail(value ?? '');
      }),
    name: !accountAlreadyExists
      ? Yup.string()
          .min(3, 'Mínimo de 3 caracteres')
          .max(48, 'Máximo de 48 caracteres')
          .matches(
            /[^0-9]{2,} [^0-9]{2,}( [^0-9]{2,}){0,}$/,
            'Digite seu nome e sobrenome'
          )
          .required('Digite seu nome e sobrenome')
      : Yup.string()
          .min(3, 'Mínimo de 3 caracteres')
          .max(48, 'Máximo de 48 caracteres'),
    whatsappPhoneNumber: !accountAlreadyExists
      ? Yup.string()
          .min(10, 'Número de telefone incompleto')
          .required('Informe um número de celular')
      : Yup.string().min(10, 'Número de telefone incompleto'),
    password: !isSocialSignOn
      ? Yup.string()
          .min(8, 'A senha deve ter pelo menos 8 caracteres')
          .required('Informe uma senha para sua conta')
      : Yup.string().min(8, 'A senha deve ter pelo menos 8 caracteres'),
  });

  const initialValues: PublicUserRequestForm = {
    email: '',
    whatsappPhoneNumber: '',
    name: '',
    password: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);

      if (isSocialSignOn) {
        try {
          const { token, person } = await publicUserService.createNewUser(
            values.name,
            values.whatsappPhoneNumber
          );

          authService.setAccessToken(token ?? '');

          dispatch(setPublicUserInfo(person));

          setIsLoading(false);
          handleShowNewUserGreeting();
        } catch {
          setIsLoading(false);

          toast.error('Algo deu errado ao criar a conta!', {
            theme: 'colored',
          });
        }

        return;
      }

      try {
        const userCredentials = accountAlreadyExists
          ? await signInWithEmailAndPassword(
              auth,
              values.email,
              values.password
            )
          : await createUserWithEmailAndPassword(
              auth,
              values.email,
              values.password
            );

        authService.setRefreshToken(userCredentials.user.refreshToken);

        const token = await userCredentials.user.getIdToken();

        authService.setAccessToken(token);

        const userData = accountAlreadyExists
          ? await publicUserService.signIn()
          : await publicUserService.createNewUser(
              values.name,
              values.whatsappPhoneNumber
            );

        authService.setAccessToken(userData.token ?? '');

        dispatch(setPublicUserInfo(userData.person));

        setIsLoading(false);

        if (accountAlreadyExists) {
          push('/');

          return;
        }

        handleShowNewUserGreeting();
      } catch (error) {
        const message = fireBaseErrorHandler(error as FirebaseError);

        toast.error(message, { theme: 'colored' });
        setIsLoading(false);
      }
    },
  });

  const { setFieldValue } = formik;

  useEffect(() => {
    if (query.name && query.email) {
      handleSignOnWithGoogle({
        email: query.email as string,
        name: query.name as string,
      });
    }
  }, []);

  return (
    <>
      <FormHeader>
        <Tooltip arrow title="Voltar">
          <IconButton
            data-testid="back-button"
            disabled={isLoading}
            style={{ marginRight: 16 }}
            onClick={() => {
              if (currentFormStep === 0) {
                push('/');
              } else if (currentFormStep === 3 && accountAlreadyExists) {
                setCurrentFormStep(0);
              } else {
                goToPreviousStep();
              }
            }}
          >
            <ArrowBack style={{ color: defaultColors.primary }} />
          </IconButton>
        </Tooltip>

        <Image
          src="/svg/ComprAqui-green-logo.svg"
          alt="ComprAqui"
          height={24}
          width={144}
        />

        <Hidden mdUp>
          <Box width={48} height={48} />
        </Hidden>
      </FormHeader>

      <AnimatePresence>
        {currentFormStep === 0 && (
          <LoginFormFirstStep
            isLoading={isLoading}
            formik={formik}
            handleChangeAccountAlreadyExists={handleChangeAccountAlreadyExists}
            handleSignOnWithGoogle={(user) => {
              console.log('user', user);
              handleSignOnWithGoogle({
                name: user.displayName,
                email: user.email,
              });
            }}
            setIsLoading={(value) => setIsLoading(value)}
            goToNextStep={goToNextStep}
            goToLastStep={goToLastStep}
          />
        )}

        {currentFormStep === 1 && (
          <LoginFormSecondStep
            isSocialSingOn={isSocialSignOn}
            formik={formik}
            goToNextStep={goToNextStep}
          />
        )}

        {currentFormStep === 2 && (
          <LoginFormThirdStep
            isLoading={isLoading}
            isSocialSignOn={isSocialSignOn}
            formik={formik}
            goToNextStep={goToNextStep}
          />
        )}

        {currentFormStep === 3 && (
          <LoginFormFourthStep
            goToResetPasswordPage={goToResetPasswordPage}
            isLoading={isLoading}
            accountAlreadyExists={accountAlreadyExists}
            formik={formik}
          />
        )}

        {currentFormStep === 4 && (
          <ResetPasswordStep backToLogin={() => setCurrentFormStep(0)} />
        )}
      </AnimatePresence>

      <Hidden mdDown>
        <Collapse in={currentFormStep === 0}>
          <Box display="flex" alignItems="center" style={{ color: '#969C98' }}>
            <Divider style={{ flex: '1 0 auto' }} />
            <Typography style={{ margin: '0 16px' }}>ou</Typography>
            <Divider style={{ flex: '1 0 auto' }} />
          </Box>

          <SocialLoginButtons
            auth={auth}
            onAuth={(token, GAMethod, userCredentials) => {
              signInWithPopUpCallback(token, userCredentials);
            }}
            setButtonLoading={() => {}}
            signInWithPopup={signInWithPopup}
            buttons={[
              {
                AuthProvider: googleAuthProvider,
                GAMethod: 'Google',
                Icon: ColoredGoogleIcon,
                text: 'continuar com google',
              },
            ]}
          />
        </Collapse>
      </Hidden>
    </>
  );
};

export default LoginForm;
