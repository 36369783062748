import React from 'react';

const SendEmailIlustration: React.FC = () => {
  return (
    <svg
      width="150"
      height="129"
      viewBox="0 0 150 129"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="11" width="129" height="129" rx="8" fill="#ECF6F0" />
      <path
        d="M0 51.2002L131.827 70.1789L149.516 88.0299L0 51.2002Z"
        fill="#3DA865"
      />
      <path
        opacity="0.6"
        d="M0 51.2002L131.827 70.1789L149.516 88.0299L0 51.2002Z"
        fill="white"
      />
      <path
        d="M0 51.2002L131.827 70.1789L125.709 95.9926L0 51.2002Z"
        fill="#3DA865"
      />
      <path
        opacity="0.2"
        d="M0 51.2002L131.827 70.1789L125.709 95.9926L0 51.2002Z"
        fill="black"
      />
      <path
        d="M123.003 21.3158L130.653 57.1682C130.852 58.1044 130.672 59.0813 130.152 59.8846C129.631 60.6879 128.814 61.252 127.878 61.4531L59.5962 76.0254C58.6602 76.2229 57.684 76.0417 56.8811 75.5216C56.0782 75.0015 55.5138 74.1847 55.3113 73.2498L47.655 37.3973C47.4578 36.4604 47.6397 35.4836 48.161 34.6805C48.6823 33.8774 49.5005 33.3136 50.4365 33.1124L118.718 18.5401C119.655 18.3428 120.632 18.5247 121.435 19.046C122.238 19.5673 122.802 20.3855 123.003 21.3215V21.3158Z"
        fill="#3DA865"
      />
      <path
        opacity="0.4"
        d="M123.003 21.3158L130.653 57.1682C130.852 58.1044 130.672 59.0813 130.152 59.8846C129.631 60.6879 128.814 61.252 127.878 61.4531L59.5962 76.0254C58.6602 76.2229 57.684 76.0417 56.8811 75.5216C56.0782 75.0015 55.5138 74.1847 55.3113 73.2498L47.655 37.3973C47.4578 36.4604 47.6397 35.4836 48.161 34.6805C48.6823 33.8774 49.5005 33.3136 50.4365 33.1124L118.718 18.5401C119.655 18.3428 120.632 18.5247 121.435 19.046C122.238 19.5673 122.802 20.3855 123.003 21.3215V21.3158Z"
        fill="black"
      />
      <path
        d="M121.32 18.9677L98.5075 46.9731C96.3918 49.5696 93.4532 51.3661 90.1777 52.0653C86.9022 52.7646 83.4861 52.3247 80.4945 50.8186L48.2331 34.5693C48.7551 33.8195 49.5428 33.2965 50.4363 33.1063L118.718 18.534C119.609 18.3444 120.539 18.4993 121.32 18.9677V18.9677Z"
        fill="#3DA865"
      />
      <path
        d="M0 51.2002L108.731 77.0429L125.709 95.9926L0 51.2002Z"
        fill="#3DA865"
      />
      <path
        opacity="0.6"
        d="M0 51.2002L108.731 77.0429L125.709 95.9926L0 51.2002Z"
        fill="white"
      />
      <path
        d="M39.6625 76.1089L39.3703 76.6079L51.4702 83.6955L51.7625 83.1966L39.6625 76.1089Z"
        fill="#3DA865"
      />
      <path
        d="M57.1047 86.3218L56.8124 86.8208L61.1634 89.3694L61.4556 88.8705L57.1047 86.3218Z"
        fill="#3DA865"
      />
      <path
        d="M0 51.2002L108.731 77.0429L87.5956 102.515L0 51.2002Z"
        fill="#3DA865"
      />
      <path
        d="M145.433 111.745C145.287 111.745 145.142 111.717 145.006 111.664L127.299 104.655C127.016 104.541 126.789 104.319 126.669 104.039C126.548 103.758 126.543 103.441 126.656 103.157C126.768 102.873 126.988 102.645 127.268 102.523C127.548 102.4 127.864 102.394 128.149 102.504L145.861 109.513C146.112 109.612 146.32 109.795 146.45 110.031C146.58 110.267 146.623 110.541 146.572 110.806C146.521 111.07 146.38 111.309 146.172 111.48C145.964 111.652 145.703 111.745 145.433 111.745V111.745Z"
        fill="#3DA865"
      />
      <path
        opacity="0.3"
        d="M145.433 111.745C145.287 111.745 145.142 111.717 145.006 111.664L127.299 104.655C127.016 104.541 126.789 104.319 126.669 104.039C126.548 103.758 126.543 103.441 126.656 103.157C126.768 102.873 126.988 102.645 127.268 102.523C127.548 102.4 127.864 102.394 128.149 102.504L145.861 109.513C146.112 109.612 146.32 109.795 146.45 110.031C146.58 110.267 146.623 110.541 146.572 110.806C146.521 111.07 146.38 111.309 146.172 111.48C145.964 111.652 145.703 111.745 145.433 111.745V111.745Z"
        fill="black"
      />
      <path
        d="M144.387 101.59C144.242 101.589 144.098 101.56 143.965 101.504L134.452 97.7159C134.31 97.6608 134.18 97.5781 134.07 97.4725C133.96 97.367 133.872 97.2408 133.811 97.101C133.749 96.9613 133.717 96.8108 133.714 96.6583C133.711 96.5058 133.738 96.3543 133.795 96.2124C133.851 96.0706 133.934 95.9412 134.04 95.8318C134.147 95.7224 134.273 95.6351 134.414 95.5749C134.554 95.5147 134.704 95.4828 134.857 95.481C135.009 95.4793 135.161 95.5077 135.302 95.5647L144.815 99.335C145.1 99.4472 145.329 99.6679 145.451 99.9487C145.574 100.23 145.58 100.547 145.468 100.833C145.387 101.054 145.24 101.246 145.046 101.381C144.853 101.516 144.623 101.589 144.387 101.59Z"
        fill="#3DA865"
      />
      <path
        opacity="0.5"
        d="M144.387 101.59C144.242 101.589 144.098 101.56 143.965 101.504L134.452 97.7159C134.31 97.6608 134.18 97.5781 134.07 97.4725C133.96 97.367 133.872 97.2408 133.811 97.101C133.749 96.9613 133.717 96.8108 133.714 96.6583C133.711 96.5058 133.738 96.3543 133.795 96.2124C133.851 96.0706 133.934 95.9412 134.04 95.8318C134.147 95.7224 134.273 95.6351 134.414 95.5749C134.554 95.5147 134.704 95.4828 134.857 95.481C135.009 95.4793 135.161 95.5077 135.302 95.5647L144.815 99.335C145.1 99.4472 145.329 99.6679 145.451 99.9487C145.574 100.23 145.58 100.547 145.468 100.833C145.387 101.054 145.24 101.246 145.046 101.381C144.853 101.516 144.623 101.589 144.387 101.59Z"
        fill="white"
      />
      <path
        d="M124.5 111.594C124.356 111.592 124.213 111.565 124.078 111.513L106.805 104.655C106.663 104.6 106.533 104.517 106.423 104.411C106.313 104.306 106.225 104.18 106.164 104.04C106.103 103.9 106.07 103.75 106.067 103.597C106.064 103.445 106.092 103.293 106.148 103.151C106.204 103.01 106.287 102.88 106.394 102.771C106.5 102.661 106.627 102.574 106.767 102.514C106.907 102.454 107.058 102.422 107.21 102.42C107.363 102.418 107.514 102.447 107.655 102.504L124.928 109.339C125.179 109.438 125.387 109.621 125.517 109.857C125.646 110.093 125.69 110.367 125.639 110.632C125.588 110.897 125.447 111.135 125.239 111.307C125.031 111.478 124.77 111.572 124.5 111.571V111.594Z"
        fill="#3DA865"
      />
      <path
        opacity="0.3"
        d="M124.5 111.594C124.356 111.592 124.213 111.565 124.078 111.513L106.805 104.655C106.663 104.6 106.533 104.517 106.423 104.411C106.313 104.306 106.225 104.18 106.164 104.04C106.103 103.9 106.07 103.75 106.067 103.597C106.064 103.445 106.092 103.293 106.148 103.151C106.204 103.01 106.287 102.88 106.394 102.771C106.5 102.661 106.627 102.574 106.767 102.514C106.907 102.454 107.058 102.422 107.21 102.42C107.363 102.418 107.514 102.447 107.655 102.504L124.928 109.339C125.179 109.438 125.387 109.621 125.517 109.857C125.646 110.093 125.69 110.367 125.639 110.632C125.588 110.897 125.447 111.135 125.239 111.307C125.031 111.478 124.77 111.572 124.5 111.571V111.594Z"
        fill="black"
      />
    </svg>
  );
};

export default SendEmailIlustration;
