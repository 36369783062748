import { ArrowForward } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';
import { defaultColors } from '../../../../helpers/utils';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: defaultColors.primary,

    transition: 'filter .2s',

    '&:hover': {
      backgroundColor: defaultColors.primary,
      filter: 'brightness(.9)',
    },
  },
}));

const NewUserGreeting: React.FC = () => {
  const classes = useStyles();
  const { push } = useRouter();

  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Image
        src="/svg/ComprAqui-green-logo.svg"
        alt="ComprAqui"
        height={24}
        width={144}
      />

      <Box display="flex" justifyContent="center" mt={6}>
        <Image
          src="/svg/welcome-illustration.svg"
          alt="Ilustração em tons de verde de uma mulher segurando uma placa escrito bem-vindo."
          height={175}
          width={235}
        />
      </Box>

      <Typography
        variant="h5"
        style={{ fontWeight: 700, textAlign: 'center', margin: '32px 0' }}
      >
        Conta criada com sucesso!
      </Typography>

      <Button
        disableElevation
        variant="contained"
        className={classes.button}
        endIcon={<ArrowForward />}
        onClick={() => push('/')}
      >
        voltar para o cardápio
      </Button>
    </Box>
  );
};

export default NewUserGreeting;
