import {
  Box,
  Button,
  Hidden,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormikProps } from 'formik';
import { motion } from 'framer-motion';
import React from 'react';
import { defaultColors } from '../../../../../../helpers/utils';
import { PublicUserRequestForm } from '../../../../../../shared/models';

const useStyles = makeStyles<Theme>((theme) => ({
  buttonContainer: {
    marginTop: 64,

    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: 0,
      width: 385,
      margin: 0,
      padding: 16,
      backgroundColor: '#fff',
    },

    [theme.breakpoints.down('sm')]: {
      left: 0,
      width: '100%',
    },
  },

  button: {
    backgroundColor: defaultColors.primary,

    transition: 'filter .2s',

    '&:hover': {
      backgroundColor: defaultColors.primary,
      filter: 'brightness(.9)',
    },
  },
}));

interface LoginFormSecondStepProps {
  isSocialSingOn: boolean;
  formik: FormikProps<PublicUserRequestForm>;
  goToNextStep: () => void;
}

const LoginFormSecondStep: React.FC<LoginFormSecondStepProps> = ({
  isSocialSingOn,
  formik,
  goToNextStep,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const { values, errors, touched, handleChange, handleBlur } = formik;

  return (
    <Box marginY={isSm ? 3 : 4}>
      <Box mb={isSm ? 3 : 4}>
        <Hidden smDown>
          <Typography variant="h5" style={{ fontWeight: 700 }}>
            Qual seu nome?
          </Typography>
        </Hidden>

        <Hidden smUp>
          <Typography variant="h6" style={{ fontWeight: 700 }}>
            Qual é seu nome?
          </Typography>
        </Hidden>

        {!isSocialSingOn ? (
          <Typography style={{ color: '#636965', marginTop: isSm ? 16 : 8 }}>
            Não existe uma conta registrada no <strong>{values.email}</strong>,
            digite seu nome para prosseguirmos com o cadastro.
          </Typography>
        ) : (
          <Typography style={{ color: '#636965', marginTop: isSm ? 16 : 8 }}>
            Digite abaixo seu nome e sobrenome.
          </Typography>
        )}
      </Box>

      <motion.div layoutId="input-container">
        <TextField
          fullWidth
          data-testid="name-input"
          variant="outlined"
          label="Nome e sobrenome"
          name="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.name !== undefined && touched.name}
          helperText={
            errors.name && touched.name
              ? errors.name
              : 'Seu nome ajudará o restaurante a saber quem está pedindo.'
          }
        />
      </motion.div>

      <motion.div
        layoutId="continue-btn-container"
        className={classes.buttonContainer}
      >
        <Button
          fullWidth
          disableElevation
          disabled={!values.name || !!errors.name}
          variant="contained"
          className={classes.button}
          onClick={goToNextStep}
        >
          continuar
        </Button>
      </motion.div>
    </Box>
  );
};

export default LoginFormSecondStep;
