import React, { useState } from 'react';

import { Box, styled, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import {
  AuthProvider,
  UserCredential,
  Auth,
  signInWithPopup,
} from 'firebase/auth';
import LoadingButton from '@mui/lab/LoadingButton';

const SocialLoginContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  marginTop: 24,
}));

const Button = styled(LoadingButton)(() => ({
  width: '100%',
  padding: 12,
}));

interface SocialLoginButtonProps {
  AuthProvider: AuthProvider;
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  text: string;
  disabled?: boolean;
  GAMethod: string;
}

type SocialLoginButtonsProps = {
  setButtonLoading: (loading: boolean) => void;
  disabledAll?: boolean;
  buttons: SocialLoginButtonProps[];
  auth: Auth;
  onAuth: (token: string, GAMethod: string, credential: UserCredential) => void;
  signInWithPopup: typeof signInWithPopup;
};

export const SocialLoginButtons: React.FC<SocialLoginButtonsProps> = ({
  setButtonLoading,
  disabledAll,
  buttons,
  auth,
  signInWithPopup,
  onAuth,
}) => {
  const [buttonIndexLoading, setButtonIndexLoading] = useState(-1);

  const handleClick = async (
    AuthProvider: AuthProvider,
    GAMethod: string,
    index: number
  ) => {
    setButtonLoading(true);
    setButtonIndexLoading(index);
    try {
      const result = await signInWithPopup(auth, AuthProvider);
      const userToken = await result.user.getIdToken();
      onAuth(userToken, GAMethod, result);
    } catch (err) {}
    setButtonLoading(false);
    setButtonIndexLoading(-1);
  };
  return (
    <SocialLoginContainer>
      {buttons.map(
        ({ AuthProvider, Icon, text, disabled, GAMethod }, index) => (
          <Button
            data-testid="social-login-button"
            key={index}
            loading={buttonIndexLoading === index}
            disabled={disabledAll || disabled || buttonIndexLoading !== -1}
            variant="outlined"
            color="primary"
            onClick={() => handleClick(AuthProvider, GAMethod, index)}
            startIcon={
              <Icon
                style={{
                  filter:
                    disabledAll || disabled || buttonIndexLoading !== -1
                      ? 'contrast(0)'
                      : 'none',
                }}
              />
            }
          >
            {text}
          </Button>
        )
      )}
    </SocialLoginContainer>
  );
};
