import { LoadingButton } from '@mui/lab';
import {
  Box,
  styled,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { User } from 'firebase/auth';
import { FormikProps } from 'formik';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { defaultColors } from '../../../../../../helpers/utils';
import { PublicUserRequestForm } from '../../../../../../shared/models';
import { publicUserService } from '../../../../../../shared/services/publicUser';
import EmailInUseDialog from './components/EmailInUseDialog';
import { SignInProvider } from '@wls-solucoes/lets-eat-types';

const useStyles = makeStyles<Theme>((theme) => ({
  buttonContainer: {
    marginTop: 32,

    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: 0,
      width: 385,
      margin: 0,
      padding: 16,
      backgroundColor: '#fff',
    },

    [theme.breakpoints.down('sm')]: {
      left: 0,
      width: '100%',
    },
  },
}));

interface StyleProps {
  isLoading: boolean;
}

const StyledLoadingButton = styled(LoadingButton)<StyleProps>(
  ({ isLoading }) => ({
    backgroundColor: !isLoading ? defaultColors.primary : undefined,

    transition: 'filter .2s',

    '&:hover': {
      backgroundColor: !isLoading ? defaultColors.primary : undefined,
      filter: 'brightness(.9)',
    },
  })
);

interface LoginFormFirstStepProps {
  isLoading: boolean;
  formik: FormikProps<PublicUserRequestForm>;
  handleChangeAccountAlreadyExists: (value: boolean) => void;
  handleSignOnWithGoogle: (user: User) => void;
  setIsLoading: (value: boolean) => void;
  goToNextStep: () => void;
  goToLastStep: () => void;
}

const LoginFormFirstStep: React.FC<LoginFormFirstStepProps> = ({
  isLoading,
  formik,
  handleChangeAccountAlreadyExists,
  handleSignOnWithGoogle,
  setIsLoading,
  goToNextStep,
  goToLastStep,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const { values, touched, errors, handleChange, handleBlur } = formik;

  const [showEmailInUseDialog, setShowEmailInUseDialog] = useState(false);

  const handleOpenEmailInUseDialog = () => {
    setShowEmailInUseDialog(true);
  };

  const handleCloseEmailInUseDialog = () => {
    setShowEmailInUseDialog(false);
  };

  const checkIfAccountExists = () => {
    setIsLoading(true);

    publicUserService
      .checkEmail(values.email)
      .then(({ exists, hasFirebaseAccount, providers }) => {
        handleChangeAccountAlreadyExists(exists);

        if (exists && hasFirebaseAccount) {
          if (providers?.[0] === SignInProvider.google) {
            handleOpenEmailInUseDialog();
          } else {
            goToLastStep();
          }
        } else {
          goToNextStep();
        }

        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        toast.error('Não foi possível validar o e-mail!', { theme: 'colored' });
      });
  };

  return (
    <>
      <EmailInUseDialog
        open={showEmailInUseDialog}
        formik={formik}
        handleSignOnWithGoogle={handleSignOnWithGoogle}
        onClose={handleCloseEmailInUseDialog}
      />

      <Box marginY={isSm ? 3 : 4}>
        <Box mb={isSm ? 3 : 4}>
          <Typography variant={isSm ? 'h6' : 'h5'} style={{ fontWeight: 700 }}>
            Entre ou cadastre-se
          </Typography>

          <Typography style={{ color: '#636965', marginTop: isSm ? 16 : 8 }}>
            Insira seu e-mail abaixo para entrar ou criar uma conta.
          </Typography>
        </Box>

        <TextField
          fullWidth
          disabled={isLoading}
          data-testid="email-input"
          variant="outlined"
          type="email"
          label="E-mail"
          name="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.email !== undefined && touched.email}
          helperText={errors.email && touched.email && errors.email}
        />

        <motion.div
          layoutId="continue-btn-container"
          className={classes.buttonContainer}
        >
          <StyledLoadingButton
            fullWidth
            disableElevation
            loading={isLoading}
            isLoading={isLoading}
            disabled={!values.email || !!errors.email}
            variant="contained"
            loadingPosition="start"
            onClick={checkIfAccountExists}
          >
            continuar
          </StyledLoadingButton>
        </motion.div>
      </Box>
    </>
  );
};

export default LoginFormFirstStep;
