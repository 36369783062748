import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  styled,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormikProps } from 'formik';
import { motion } from 'framer-motion';
import React from 'react';
import InputMask from 'react-input-mask';
import { defaultColors } from '../../../../../../helpers/utils';
import { PublicUserRequestForm } from '../../../../../../shared/models';

const useStyles = makeStyles<Theme>((theme) => ({
  buttonContainer: {
    marginTop: 48,

    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: 0,
      width: 385,
      margin: 0,
      padding: 16,
      backgroundColor: '#fff',
    },

    [theme.breakpoints.down('sm')]: {
      left: 0,
      width: '100%',
    },
  },

  button: {
    backgroundColor: defaultColors.primary,

    transition: 'filter .2s',

    '&:hover': {
      backgroundColor: defaultColors.primary,
      filter: 'brightness(.9)',
    },
  },
}));

interface StyleProps {
  isLoading: boolean;
}

const StyledLoadingButton = styled(LoadingButton)<StyleProps>(
  ({ isLoading }) => ({
    backgroundColor: !isLoading ? defaultColors.primary : undefined,

    transition: 'filter .2s',

    '&:hover': {
      backgroundColor: !isLoading ? defaultColors.primary : undefined,
      filter: 'brightness(.9)',
    },
  })
);

interface LoginFormThirdStepProps {
  isLoading: boolean;
  isSocialSignOn: boolean;
  formik: FormikProps<PublicUserRequestForm>;
  goToNextStep: () => void;
}

const LoginFormThirdStep: React.FC<LoginFormThirdStepProps> = ({
  isLoading,
  isSocialSignOn,
  formik,
  goToNextStep,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const { values, errors, touched, setFieldValue, handleBlur, handleSubmit } =
    formik;

  return (
    <Box marginY={isSm ? 3 : 4}>
      <Box mb={isSm ? 3 : 4}>
        <Typography variant={isSm ? 'h6' : 'h5'} style={{ fontWeight: 700 }}>
          Qual seu número de celular?
        </Typography>

        <Typography style={{ color: '#636965', marginTop: isSm ? 16 : 8 }}>
          Insira seu número de celular.
        </Typography>
      </Box>

      <InputMask
        mask={
          values.whatsappPhoneNumber.replace(/[^\d]+/g, '').length < 11
            ? '(99) 9999-99999'
            : '(99) 99999-9999'
        }
        value={values.whatsappPhoneNumber}
        onChange={(e) => {
          setFieldValue(
            'whatsappPhoneNumber',
            e.target.value.replace(/[^\d]+/g, '')
          );
        }}
        onBlur={handleBlur}
      >
        {() => (
          <TextField
            fullWidth
            data-testid="phone-number-input"
            variant="outlined"
            type="tel"
            label="Celular"
            name="whatsappPhoneNumber"
            error={
              errors.whatsappPhoneNumber !== undefined &&
              touched.whatsappPhoneNumber
            }
            helperText={
              errors.whatsappPhoneNumber && touched.whatsappPhoneNumber
                ? errors.whatsappPhoneNumber
                : 'Informações e atualizações sobre os pedidos serão enviados para este número.'
            }
          />
        )}
      </InputMask>

      <motion.div
        layoutId="continue-btn-container"
        className={classes.buttonContainer}
      >
        {isSocialSignOn ? (
          <StyledLoadingButton
            fullWidth
            disableElevation
            loading={isLoading}
            isLoading={isLoading}
            loadingPosition="start"
            disabled={
              !values.whatsappPhoneNumber || !!errors.whatsappPhoneNumber
            }
            variant="contained"
            className={classes.button}
            onClick={() => handleSubmit()}
          >
            criar conta
          </StyledLoadingButton>
        ) : (
          <Button
            fullWidth
            disableElevation
            disabled={
              !values.whatsappPhoneNumber || !!errors.whatsappPhoneNumber
            }
            variant="contained"
            className={classes.button}
            onClick={goToNextStep}
          >
            continuar
          </Button>
        )}
      </motion.div>
    </Box>
  );
};

export default LoginFormThirdStep;
