import { GetServerSideProps } from 'next';
import Login from '../containers/LoginContainer/LoginContainer';

export const getServerSideProps: GetServerSideProps = async () => ({
  props: {
    hideHeader: true,
  },
});

export default Login;
