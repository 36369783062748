import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, TextField, TextFieldProps, Tooltip } from '@mui/material';
import React, { useState } from 'react';

const PasswordInput: React.FC<TextFieldProps> = props => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <TextField
      {...props}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <Tooltip
            arrow
            title={showPassword ? 'Ocultar senha' : 'Exibir senha'}
          >
            <IconButton onClick={toggleShowPassword}>
              {showPassword ? (
                <VisibilityOff data-testid="hide-icon" />
              ) : (
                <Visibility data-testid="show-icon" />
              )}
            </IconButton>
          </Tooltip>
        ),
      }}
    />
  );
};

export default PasswordInput
