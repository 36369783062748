import { Box, Hidden, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { NextPage } from 'next';
import Head from 'next/head';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { defaultColors } from '../../helpers/utils';
import { setEstablishmentData } from '../../redux/reducers/establishment/reducer';
import { Establishment } from '../../shared/models';
import LoginForm from './components/LoginForm';
import NewUserGreeting from './components/NewUserGreeting';

const useStyles = makeStyles<Theme>((theme) => ({
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '40%',
    padding: '32px 16px 0',

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  formHeader: {
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
    },
  },
}));

interface LoginProps {
  establishmentData?: Establishment;
}

const Login: NextPage<LoginProps> = ({ establishmentData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [showNewUserGreeting, setShowNewUserGreeting] = useState(false);

  const handleShowNewUserGreeting = () => {
    setShowNewUserGreeting(true);
  };

  useEffect(() => {
    if (establishmentData) {
      dispatch(setEstablishmentData(establishmentData));
    }
  }, []);

  return (
    <>
      <Head>
        <title>Entre ou cadastre-se</title>
      </Head>

      <Box display="flex" height="100vh">
        <Box className={classes.formContainer}>
          <Box width={385}>
            {!showNewUserGreeting ? (
              <LoginForm
                handleShowNewUserGreeting={handleShowNewUserGreeting}
              />
            ) : (
              <NewUserGreeting />
            )}
          </Box>
        </Box>

        <Hidden mdDown>
          <Box
            height="100%"
            width="60%"
            style={{ backgroundColor: defaultColors.primary }}
          />
        </Hidden>
      </Box>
    </>
  );
};

export default Login;
