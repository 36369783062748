import React, { useEffect } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../../../../../firebase/auth';
import SendEmailIlustration from '../../../../../../modules/assets/SendEmailIlustration';
import { ArrowBack } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '80px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  title: {
    fontWeight: 700,
    fontSize: '1.5rem',
    lineHeight: '130%',
  },
  subtitle: {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '150%',
    color: '#636965',
  },
}));

type ResetPasswordStepProps = {
  backToLogin: () => void;
};

const ResetPasswordStep: React.FC<ResetPasswordStepProps> = ({
  backToLogin,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <SendEmailIlustration />
      <Typography className={classes.title} style={{ margin: '30px 0 16px' }}>
        Cheque seu e-mail!
      </Typography>
      <Typography className={classes.subtitle} style={{ marginBottom: 32 }}>
        Enviamos instruções para redefinir sua senha para seu e-mail.
      </Typography>
      <Button
        onClick={backToLogin}
        startIcon={<ArrowBack />}
        data-testid="back-to-login-button"
      >
        voltar para login
      </Button>
    </Box>
  );
};

export default ResetPasswordStep;
