import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  Slide,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { signInWithPopup, User } from 'firebase/auth';
import { FormikProps } from 'formik';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import ColoredGoogleIcon from '../../../../../../../../../public/svg/ColoredGoogleIcon';
import { auth } from '../../../../../../../../firebase/auth';
import { fireBaseErrorHandler } from '../../../../../../../../firebase/errorHandler';
import { googleAuthProvider } from '../../../../../../../../firebase/providers';
import {
  setPublicUserInfo,
  setUserAvatar,
} from '../../../../../../../../redux/reducers/publicUser/reducer';
import { PublicUserRequestForm } from '../../../../../../../../shared/models';
import { authService } from '../../../../../../../../shared/services/authService';
import { publicUserService } from '../../../../../../../../shared/services/publicUser';

const DialogPaper = styled(Paper)(() => ({
  width: 450,
  maxWidth: '450px !important',
  margin: '8px !important',
  borderRadius: 8,
}));

const DialogHeader = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '32px 32px 0',
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface EmailInUseDialogProps {
  open: boolean;
  formik: FormikProps<PublicUserRequestForm>;
  handleSignOnWithGoogle: (user: User) => void;
  onClose: () => void;
}

const EmailInUseDialog: React.FC<EmailInUseDialogProps> = ({
  open,
  formik,
  handleSignOnWithGoogle,
  onClose,
}) => {
  const dispatch = useDispatch();
  const { push } = useRouter();

  const { values } = formik;

  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    if (!isLoading) {
      onClose();
    }
  };

  const handleSignInWithGoogle = () => {
    setIsLoading(true);

    signInWithPopup(auth, googleAuthProvider)
      .then(({ user }) => {
        dispatch(setUserAvatar(user.photoURL));

        user
          .getIdToken()
          .then(async (token) => {
            let hasAccount = true;

            authService.setAccessToken(token);

            if (user.email !== values.email) {
              try {
                hasAccount = (
                  await publicUserService.checkEmail(user.email ?? '')
                ).exists;
              } catch (e) {
                setIsLoading(false);
                toast.error('Não foi possível validar o e-mail!', {
                  theme: 'colored',
                });
              }
            }

            if (hasAccount) {
              try {
                const { person, token } = await publicUserService.signIn();
                authService.setAccessToken(token ?? '');

                dispatch(setPublicUserInfo(person));

                setIsLoading(false);
                onClose();
                push('/');
              } catch (e) {
                setIsLoading(false);
                toast.error('Algo deu errado ao obter os dados do usuário!', {
                  theme: 'colored',
                });
              }
            } else {
              handleSignOnWithGoogle(user);
            }
          })
          .catch((e) => {
            setIsLoading(false);
            toast.error('Algo deu errado ao obter os dados do usuário!', {
              theme: 'colored',
            });
          });
      })
      .catch((error) => {
        setIsLoading(false);

        if (error.code !== 'auth/popup-closed-by-user') {
          const message = fireBaseErrorHandler(error);

          toast.error(message, { theme: 'colored' });
        }
      });
  };

  return (
    <Dialog
      open={open}
      onBackdropClick={handleClose}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperComponent={DialogPaper}
    >
      <DialogHeader>
        <Typography variant="h6" style={{ fontWeight: 700, maxWidth: 288 }}>
          Ops, parece que esse e-mail já tem registro!
        </Typography>

        <Tooltip arrow title="Fechar">
          <IconButton
            data-testid="close-button"
            style={{ height: 'min-content' }}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </Tooltip>
      </DialogHeader>

      <DialogContent style={{ padding: '16px 32px 0' }}>
        <Typography style={{ fontSize: '1.125rem', color: '#636965' }}>
          Esse e-mail não possui senha, ele foi registrado pela Google. Faça
          login com a mesma para prosseguir{' '}
          <span role="igm" aria-label="rosto com olho piscando">
            😉
          </span>
        </Typography>

        <Box mt={4} display="flex" justifyContent="center">
          <Image
            src="/svg/sign-up-illustration.svg"
            alt="Ilustração em tons de verde e cinza mostrando uma pessoa interagindo com um formulário de login."
            height={170}
            width={200}
          />
        </Box>
      </DialogContent>

      <DialogActions
        disableSpacing
        style={{ flexDirection: 'column', gap: 16, padding: 32 }}
      >
        <Button
          fullWidth
          disableElevation
          disabled={isLoading}
          variant="contained"
          color="primary"
          onClick={handleClose}
        >
          entendi
        </Button>

        <LoadingButton
          fullWidth
          loading={isLoading}
          variant="outlined"
          color="primary"
          startIcon={!isLoading && <ColoredGoogleIcon />}
          onClick={handleSignInWithGoogle}
        >
          continuar com google
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EmailInUseDialog;
